<template>
  <div class="container">
    <div class="content flex">
      <img src="../assets/images/icon/from_left.png" />
      <div>
        <div class="form">
          <div class="title">郑州约克计算技术有限公司</div>
          <login @switchRegister="switchRegister" v-show="islogin"></login>
          <register @switchLogin="switchLogin" v-show="!islogin"></register>
        </div>
      </div>
    </div>
    <div class="triangle"></div>
    <div class="href">
      <a href="https://beian.miit.gov.cn" target="_blank"
        >Copyright@2022 郑州约克计算技术有限公司 豫ICP备***号</a
      >
    </div>
  </div>
</template>
<script>
import login from "@/components/login/login";
import register from "@/components/login/register";
export default {
  name: "home",
  components: { login, register },
  data() {
    return {
      islogin: true,
    };
  },
  mounted(){
  },
  methods: {
    switchLogin() {
      this.islogin = true;
    },
    switchRegister() {
      this.islogin = false;
    },
  },
  deactivated () { 
    //清除keep-alive的缓存
    this.$destroy(true)
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(60deg, #162b5b, #0793a1);
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.triangle {
  width: 200%;
  height: 400%;
  background: #ffffff;
  position: absolute;
  z-index: 1;
  transform: rotate(154deg);
  top: 10%;
  left: 20%;
}
.content {
  width: 1431px;
  height: 740px;
  background: #ffffff;
  box-shadow: 0px 10px 19px 5px rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 6px;
  overflow: hidden;
}
.content > img {
  width: 700px;
}
.content > div {
  flex: 1;
  position: relative;
}
.content > div .form {
  width: 80%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9;
}
.form .title {
  font-size: 30px;
  font-weight: 500;
  color: #02b2b5;
  line-height: 30px;
  text-align: center;
  letter-spacing: 4px;
  margin: 200px 0 100px 0;
}
.href {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
  z-index: 3;
}
.href a {
  color: #333333;
  text-decoration: none;
}
@media screen and (max-width: 1440px) {
  .content {
    width: 1200px;
    height: 640px;
  }
  .content > img {
    width: 500px;
  }
  .form .title {
    margin: 160px 0 70px 0;
  }
}
</style>
