<template>
  <div class="login">
    <form method="post" onsubmit="return func()">
      <div class="formItem flex">
        <label for="name">
          <img src="../../assets/images/icon/user.png" />
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="请输入用户名"
          required
        />
      </div>
      <div class="formItem flex">
        <label for="password">
          <img src="../../assets/images/icon/password.png" />
        </label>
        <input
          type="text"
          name="password"
          id="password"
          placeholder="请输入密码"
          required
        />
      </div>
      <div class="formItem flex">
        <label for="code">
          <img src="../../assets/images/icon/Verification.png" />
        </label>
        <input
          type="text"
          name="code"
          id="code"
          placeholder="验证码"
          required
        />
        <span>获取验证码</span>
      </div>
      <div class="flex justify-between opertion">
        <div @click="switchLogin">登录</div>
        <div>忘记密码？</div>
      </div>
      <button type="button">注册</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {};
  },
  methods: {
    switchLogin() {
      this.$emit("switchLogin");
    },
  },
};
</script>

<style scoped>
form {
  width: 324px;
  margin: 0 auto;
}
.formItem {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 0 15px 5px;
  margin-top: 15px;
  position: relative;
}
.formItem img {
  width: 20px;
  height: 20px;
}
.formItem input {
  border: none;
  flex: 1;
  margin-left: 20px;
  letter-spacing: 2px;
  background: #ffffff;
  outline: none;
  -webkit-appearance: button;
  -webkit-appearance: none;
}
.formItem span {
  display: inline-block;
  width: 99px;
  height: 26px;
  background: #f4f4f4;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #02b2b5;
  line-height: 26px;
  position: absolute;
  right: 0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
}
input::-webkit-input-placeholder {
  color: #999999;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
}
.opertion {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}
.opertion div {
  cursor: pointer;
}
button {
  width: 324px;
  outline: none;
  border: none;
  height: 50px;
  line-height: 50px;
  background: #02b2b5;
  text-align: centers;
  font-size: 18px;
  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
}
</style>
